export const workflow = {
  GPO: {
    ControlName : "LTCOmnicare",
    Ncpdp: {
      required: true,
    },
    CorporateName: {
      label: "Corporate Name",
      required: true,
    },
    NameOf: {
      label: "Name of Pharmacy",
      required: true,
    },
    BusinessAs: {
      label: "Pharmacy Doing Business As",
      required: false,
    },
    County: {
      show: false,
      required: true,
    },
    Hin: {
      multiple: false,
      show: true,
    },
    DEADate: {
      show: true,
      required: true,
    },
    DEA:{
      required: false
    }, 
    NPI:{
      required: false
    },
  },
  "LTC Network": {
    ControlName : "Network",
    Ncpdp: {
      required: true,
    },
    CorporateName: {
      label: "Corporate Name",
      required: true,
    },
    NameOf: {
      label: "Name of Provider",
      required: true,
    },
    BusinessAs: {
      label: "Provider Doing Business As",
      required: false,
    },
    County: {
      show: true,
      required: true,
    },
    Hin: {
      multiple: false,
      show: false,
    },
    DEADate: {
      show: false,
      required: false,
    },
    DEA:{
      required: true,
    },
    NPI:{
      required: true
    },
  },
  // HME: {
  //   Ncpdp: {
  //     required: false,
  //   },
  //   CorporateName: {
  //     label: "Corporate Legal Name",
  //   },
  //   NameOf: {
  //     label: "Facility Name",
  //   },
  //   BusinessAs: {
  //     label: "Facility DBA",
  //     required: false,
  //   },
  //   County: {
  //     show: false,
  //   },
  //   Hin: {
  //     multiple: true,
  //     show: true,
  //   },
  // },
  "Home Medical Equipment": {
    ControlName : "HomeMedical",
    Ncpdp: {
      required: false,
    },
    CorporateName: {
      label: "Corporate Legal Name",
      required: false,
    },
    NameOf: {
      label: "Facility Name",
      required: false,
    },
    BusinessAs: {
      label: "Facility DBA",
      required: false,
    },
    // CorporateName: {
    //   label: "Corporate Name",
    // },
    // NameOf: {
    //   label: "Name of Pharmacy",
    // },
    // BusinessAs: {
    //   label: "Pharmacy Doing Business As",
    //   required: false,
    // },
    County: {
      show: false,
      required: false,
    },
    Hin: {
      multiple: true,
      show: true,
    },
    DEADate: {
      show: true,
      required: false,
    },
    DEA: {
      required:false,
    }, 
    NPI:{
      required: false
    },
  },

  "Medical Distributor": {
    ControlName : "HomeMedical",
    Ncpdp: {
      required: false,
    },
    CorporateName: {
      label: "Corporate Legal Name",
      required: false,
    },
    NameOf: {
      label: "Facility Name",
      required: false,
    },
    BusinessAs: {
      label: "Facility DBA",
      required: false,
    },
    // CorporateName: {
    //   label: "Corporate Name",
    // },
    // NameOf: {
    //   label: "Name of Pharmacy",
    // },
    // BusinessAs: {
    //   label: "Pharmacy Doing Business As",
    //   required: false,
    // },
    County: {
      show: false,
      required: false,
    },
    Hin: {
      multiple: true,
      show: true,
    },
    DEADate: {
      show: true,
      required: false,
    },
    DEA: {
      required:false,
    }, 
    NPI:{
      required: false
    },
  },
  HME: {
    ControlName : "HME",
    Ncpdp: {
      required: true,
    },
    CorporateName: {
      label: "Corporate Legal Name",
      required: false,
    },
    NameOf: {
      label: "Facility Name",
      required: false,
    },
    BusinessAs: {
      label: "Facility DBA",
      required: false,
    },
    // CorporateName: {
    //   label: "Corporate Name",
    // },
    // NameOf: {
    //   label: "Name of Pharmacy",
    // },
    // BusinessAs: {
    //   label: "Pharmacy Doing Business As",
    //   required: false,
    // },
    County: {
      show: false,
      required: false,
    },
    Hin: {
      multiple: true,
      show: true,
    },
    DEADate: {
      show: true,
      required: false,
    },
    DEA: {
      required:false,
    }, 
    NPI:{
      required: false
    },
  },
  "Alt Site": {
    ControlName : "AltSite",
    Ncpdp: {
      required: false,
    },
    CorporateName: {
      label: "Corporate Legal Name",
      required: false,
    },
    NameOf: {
      label: "Facility Name",
      required: false,
    },
    BusinessAs: {
      label: "Facility DBA",
      required: false,
    },
    // CorporateName: {
    //   label: "Corporate Name",
    // },
    // NameOf: {
    //   label: "Name of Pharmacy",
    // },
    // BusinessAs: {
    //   label: "Pharmacy Doing Business As",
    //   required: false,
    // },
    County: {
      show: false,
      required: false,
    },
    Hin: {
      multiple: true,
      show: true,
    },
    DEADate: {
      show: true,
      required: false,
    },
    DEA: {
      required:false,
    }, 
    NPI:{
      required: false
    },
  },
};
