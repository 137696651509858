import React, { useState, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { ContractFormModal } from "./ContractFormModal";
import { adminAPI, memberAPI } from "../../utility/axios";
import useAzureAuthentication from "../../hooks/useAzureAuthentication";
import { MemberApplicationContext } from "../../context/MemberApplicationContext";
import useAzureAuthenticationAdmin from "../../hooks/useAzureAuthenticationAdmin";
import { b64toBlob } from "../../helpers/ConversionHelpers";

const CreatePacketForm = (props) => {
  let { id, applicationType } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [isPacketSubmitting, setisPacketSubmitting] = useState(false);
  const [isPreviewSubmitting, setIsPreviewSubmitting] = useState(false);
  const [isBlankSubmitting, setIsBlankSubmitting] = useState(false);
  const [hasContracts, setHasContacts] = useState(false);
  const [envelopePreviewRedirectURL, setEnvelopePreviewRedirectURL] =
    useState();

  const [hasExistingDocusignPreview, setHasExistingDocusignPreview] =
    useState(false);
  const memberApplication = useContext(MemberApplicationContext);
  const [envelopeId, setEnvolopeId] = useState(false);
  const [blankPacket, setBlankPacket] = useState(false);
  let { authInfo } = useAzureAuthentication();
  let { authInfoAdmin } = useAzureAuthenticationAdmin();

  const history = useHistory();

  useEffect(() => {
    if (envelopePreviewRedirectURL)
      window.open(envelopePreviewRedirectURL, "_blank");
  }, [envelopePreviewRedirectURL]);

  useEffect(() => {
    if (memberApplication.service) {
    } else {
      memberApplication.onIDChange(id);
    }
    if(memberApplication.envelopeId)
     setEnvolopeId(memberApplication.envelopeId);
  }, [authInfo]);

  useEffect(() => {
    if (authInfoAdmin) {
      checkForExistingDocusignPreview();
    }
  }, [authInfoAdmin]);

  useEffect(() => {
    if (memberApplication.id) {
    }
  }, [memberApplication.id]);

  function routeChange(path) {
    if (path) {
      history.push(`${path ?? ""}/${id}`);
    }
  }

  function onBlankPacketChange(e) {

    if (e != undefined) {
      setBlankPacket(e.target.checked);
    }
  }
  async function checkForExistingDocusignPreview() {
    async function getHasExistingDocusignPreview() {
      const res = await adminAPI.get(
        "api/DocusignQueue/DoesApplicationPreviewExist",
        {
          params: {
            applicationId: id,
          },
          headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
        }
      );

      setHasExistingDocusignPreview(res.data);
    }

    await getHasExistingDocusignPreview();
  }
  function onBackClick(e) {
    e.preventDefault();
    let gpoRoute = memberApplication.workflow !== "LTC Network" ? "/AdditionalOutputInfo" : "/AdditionalInformation";
    return routeChange(gpoRoute);
    
   // routeChange("/AdditionalInformation");
  }

  async function onSubmit(e) {
    async function GetDocusignQueueId() {
      const res = await adminAPI.post(
        "api/DocusignQueue/UpdateDocusignQueue",
        {
          DocusignQueue: {
            UpdateFor: "INSERT",
            MEMAPPID: id,
            SENDEMAIL: 1,
            QUEUEID: null,
            DOCSTATUS: "E",
            STATUS: "A",
            EMAILSUBJECT: "EmailTestSubject",
            EMAILBLURB: "EmailTestBlurb",
            USER: authInfo.userEmail,
            SERVICE: "0",
            ISPREVIEWTEMPLATE: false,
          },
        },
        {
          headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
        }
      );

      return res.data;
    }

    async function GetDocusignQueueAndPreviewDetails() {
      const res = await adminAPI.post(
        "api/DocusignQueue/DocusignQueuePreview",
        {
          DocusignQueue: {
            UpdateFor: "INSERT",
            MEMAPPID: id,
            SENDEMAIL: 1,
            QUEUEID: null,
            DOCSTATUS: "E",
            STATUS: "A",
            EMAILSUBJECT: "EmailTestSubject",
            EMAILBLURB: "EmailTestBlurb",
            USER: authInfo.userEmail,
            SERVICE: "0",
            ISPREVIEWTEMPLATE: true,
            PreviewReturnUrl: window.location.href,
            BLANKPACKET:blankPacket
          },
        },
        {
          headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
        }
      );

      return res.data;
    }

    async function SetApplicationDocusignQueueId(docusignQueueId) {
      console.log(docusignQueueId);
      const res = await memberAPI.post(
        "api/MemberForm/UpdateApplicationDocusignQueueId",
        {
          user: authInfo.userEmail,
          applicationId: id,
          docusignQueueId: docusignQueueId,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    async function GetEnvopleId() {
      console.log(id);
      const res = await memberAPI.get("api/MemApp/FetchMemberAppEnvelopeId", {
        params: {
          id: id,
        },
        headers: { Authorization: `Bearer ${authInfo.userAccessToken}` },
      });
      setEnvolopeId(res.data.EnvelopeId);
    }

    async function SetApplicationDocusignPreviewQueueId(docusignQueueId) {
      console.log(docusignQueueId);
      const res = await memberAPI.post(
        "api/MemberForm/UpdateApplicationDocusignQueuePreviewId",
        {
          user: authInfo.userEmail,
          applicationId: id,
          docusignQueueId: docusignQueueId,
        },
        { headers: { Authorization: `Bearer ${authInfo.userAccessToken}` } }
      );
    }

    if (e.target.id == "send") {
      // This will return the QueueId and will call the packet generation to run in the background
      setisPacketSubmitting(true);
      try {
        let docusignQueueId = await GetDocusignQueueId();

        await SetApplicationDocusignQueueId(docusignQueueId);
        memberApplication.onDocusignIdChange(docusignQueueId);
        await GetEnvopleId();
      } catch (error) {
        console.error(error);
        alert(
          "An error has occured while generating the packet. If the error continues please contact IT."
        );
      }
      setisPacketSubmitting(false);
    } else if (e.target.id == "preview" || e.target.id == "preview-icon") {
      setIsPreviewSubmitting(true);

      try {
        let docusignQueueDetails = await GetDocusignQueueAndPreviewDetails();
        await SetApplicationDocusignPreviewQueueId(
          docusignQueueDetails.QueueId
        );
        setEnvelopePreviewRedirectURL(docusignQueueDetails.EnvelopePreviewUrl);
        setHasExistingDocusignPreview(true);
      } catch (error) {
        console.error(error);
        alert(
          "An error has occured while generating the packet. If the error continues please contact IT."
        );
      }

      setIsPreviewSubmitting(false);
    } else {
      // eslint-disable-next-line no-throw-literal
      throw `Invalid submit source of ${e.target.id} shoube be send or preview`;
    }
  }

  async function downloadBlankPacket(){
    async function fetchPacket (){
      let param = {
        MEMAPPID: id,
        
      }
      const res = await adminAPI.post("api/DocusignQueue/GetBlankPacket",{
        Args: JSON.stringify(param),
      },{
        headers: {Authorization: `Bearer ${authInfoAdmin.userAccessToken}`}
      });
      return res.data
    }
    setIsBlankSubmitting(true);
    const data = await fetchPacket();
    setIsBlankSubmitting(false);
    const blob = b64toBlob(data, "application/pdf");
    let url = URL.createObjectURL(blob);
    let tab = window.open();
    tab.location.href = url;
  }

  async function getPdf() {
    setisPacketSubmitting(true);
    async function fetchPdf() {
      let test = {
        ENVELOPEID: envelopeId,
        VIEWPACKET: true,
      };
      const res = await adminAPI.post(
        "api/Docusign/GetEnvelopePreviewDocument",
        {
          Args: JSON.stringify(test),
        },
        {
          headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
        }
      );

      return res.data;
    }

    const data = await fetchPdf();
    const blob = b64toBlob(data, "application/pdf");

    let url = URL.createObjectURL(blob);

    let tab = window.open();
    tab.location.href = url;

    setisPacketSubmitting(false);
    // If we want to directly download
    //let a = document.createElement("a");
    //a.style = "display: none";
    //document.body.appendChild(a);
    //a.href = url;
    // a.download = 'packet.pdf'; // gives it a name via an a tag
    //a.click();
    //window.URL.revokeObjectURL(url);
  }

  async function fetchExistingPreview() {
    async function getPreviousPreviewUrl() {
      const res = await adminAPI.get(
        "api/DocusignQueue/GetExistingEnvelopePreviewUrl",
        {
          params: {
            applicationId: id,
            returnUrl: window.location.href,
          },
          headers: { Authorization: `Bearer ${authInfoAdmin.userAccessToken}` },
        }
      );
      return res.data;
    }
    const url = await getPreviousPreviewUrl();
    setEnvelopePreviewRedirectURL(url);
  }
  return (
    <div>
      <br />
      <div className="mb-3 d-grid gap-4 d-md-flex justify-content-md-center">
        <button
          className="btn btn-primary"
          onClick={onBackClick}
          disabled={isPreviewSubmitting || isPacketSubmitting || isEditMode}
        >
          Back
        </button>
        {applicationType === "GPO" && (
          <button
            className="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#contract-grid-modal"
            disabled={isPreviewSubmitting || isPacketSubmitting || isEditMode}
          >
            Contracts List
          </button>
        )}
        {applicationType === "GPO" && (
          <ContractFormModal
            id={id}
            isReadOnly={memberApplication.isReadOnly}
            onSave={(contracts) => setHasContacts(contracts !== "")}
          />
        )}
        {envelopeId ===
        "00000000-0000-0000-0000-000000000000" ? (
          <button
            id="send"
            className="btn btn-primary"
            // disabled={
            //   memberApplication.isReadOnly ||
            //   isPreviewSubmitting ||
            //   isPacketSubmitting ||
            //   isEditMode ||
            //   (applicationType === "GPO" && !hasContracts)
            // }
            type="button"
            onClick={onSubmit}
            disabled = {isPacketSubmitting}
          >
            {isPacketSubmitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </>
            ) : (
              <>Submit Packet </>
            )}
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={getPdf}
            disabled={isPacketSubmitting}
          >
            {isPacketSubmitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </>
            ) : (
              <>View Packet</>
            )}
          </button>
        )}

        <span className="btn-group">
          <button
            onClick={fetchExistingPreview}
            // disabled={
            //   memberApplication.isReadOnly ||
            //   isPreviewSubmitting ||
            //   isPacketSubmitting ||
            //   isEditMode ||
            //   (applicationType === "GPO" && !hasContracts) ||
            //   !hasExistingDocusignPreview
            // }
            className="btn btn-secondary"
          >
            {hasExistingDocusignPreview
              ? "Click Here to Preview"
              : "No Existing Preview"}
          </button>
          <button
            id="preview"
            className="btn btn-primary"
            // disabled={
            //   memberApplication.isReadOnly ||
            //   isPreviewSubmitting ||
            //   isPacketSubmitting ||
            //   isEditMode ||
            //   (applicationType === "GPO" && !hasContracts)
            // }
            type="button"
            onClick={onSubmit}
          >
            {isPreviewSubmitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </>
            ) : (
              <i
                id="preview-icon"
                className={`bi bi-${
                  hasExistingDocusignPreview
                    ? "arrow-clockwise"
                    : "plus-circle-fill"
                }`}
                title={
                  hasExistingDocusignPreview ? "Refresh Preview" : "Add Preview"
                }
              />
            )}
          </button>
         
        </span>

        { hasExistingDocusignPreview &&<button type="button" id="blank-packet" className="btn btn-primary" onClick={downloadBlankPacket} >
        {isBlankSubmitting ? (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </>
            ) : (<>Download Blank Packet</>)}
          </button>} 
      </div>
      <br />
      <input
          className="form-check-input"
          type="checkbox"
          disabled={memberApplication.isReadOnly}
          value=""
          onChange={onBlankPacketChange}
          id="BlankPacket"
          checked={blankPacket}
          name="BlankPacket"
        />
        <label
          className="form-check-label"
          htmlFor="BlankPacket"
        >
          Blank Packet
        </label>
        <br />
    </div>
  );
};

export default CreatePacketForm;
